import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DemandsService } from 'src/app/shared/services/demands/demands.service';
import { Clipboard } from '@angular/cdk/clipboard';
import Swal from 'sweetalert2';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AreaService } from '../../services/user-management/area.service';

import emojis from '../../../../assets/argon-dashboard-master/assets/img/claro/icons/emojis/emojis.json';
import { DemandTasksService } from '../../services/demands/demand-tasks.service';
import { UserService } from '../../services/user-management/user.service';
import { StatusService } from '../../services/demands/status.service';

@Component({
  selector: 'app-demand-table',
  templateUrl: './demand-table.component.html',
  styleUrls: ['./demand-table.component.scss']
})
export class DemandTableComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/argon-dashboard-master/assets/animation/animation_ll6pcbjg.json'
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  // filters
  query = ''
  order = ''
  status_id = '';
  area_id:any = '';
  deadline:any = ''
  page = 1
  range = 'all';
  pagination: Array<number> = []
  @Input() limit: any = null
  @Input() context:string = 'demand';
  @Input() solicitations:boolean = false
  @Input() defaultFilter: any = {};

  status:any;
 
  // config 
  @Input() title = 'DEMANDAS'
  @Input() sharedUrl: any
  @Input() emptyTableText = 'Nenhuma demanda no momento'
  @Input() addNew = true;
  skeleton = true;

  //demands
  demandsTasks: any = []
  demands: any = []
  demand_id: any = 0
  
  header: any
  client: any
  areas:any = '';

  showFiltersBox:boolean = false;

  demandsCount = 0;

  currentUser:any = null;

  selectedRowIndex: number | null = null;

  constructor(
    private demandsService: DemandsService, 
    private demandTaskService: DemandTasksService,
    private areaService: AreaService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private userService: UserService,
    private statusService: StatusService,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();
    console.log(this.currentUser)
    // this.area_id = this.currentUser.area_id;
    console.log(this.context)
    this.getStatus();
    this.getItems();
    this.getFilters();
  }

  getStatus() { 

    let context = this.context;
    if(this.context == 'tasktype') context = 'task';
      
    this.demandsCount = 0;

    this.statusService.getAll({
      context: context
    }).subscribe({
      next: (result) => {
        this.status = result
        console.log(this.status);
        console.log(this.demandsCount);
      },
      error: (err) => console.log(err)
    })
  }

  getFilters() { 
    this.getAreas()
  }

  getAreas() { 
    this.areaService.getAll().subscribe({
      next: (result) => this.areas = result,
      error: (err) => console.log(err)
    })
  }


  resetFilters() {
    this.limit = '', 
    this.status_id = '', 
    this.query = '',
    this.order = '',
    this.range = 'all';
    this.page = 1,
    this.context = 'demand',
    this.deadline = '',
    this.area_id = '',
    this.solicitations = false;
  }

  getDemands() {
    this.skeleton = true
    this.route.paramMap.subscribe(params => {
      let status = params.get('status')
      
      let filter = { 
        ...this.defaultFilter,
        status_id: this.status_id, 
        limit:    this.limit, 
        query:    this.query,
        order:    this.order,
        range:    this.range,
        page:     this.page,
        context:  this.context,
        deadline:     this.deadline,
        area_id:  this.area_id,
        solicitations: this.solicitations
      }

      this.demandsService.getAll(filter).subscribe({
        next: (result: any) => {
          console.log('DEMANDAS', result)
          this.demands = result
          console.log(result)
          this.limit ? this.demands.data = this.demands.data.slice(0, this.limit) : ''
          this.skeleton = false
          if(this.pagination.length == 0) {
            for (let i = 1; i <= result.last_page; i++) {
              this.pagination.push(i)
            }
          }
        },
        error: err => console.log(err)
      })
    })
  }

  getDemandsTasks() {
    this.skeleton = true
    this.route.paramMap.subscribe(params => {
      let status = params.get('status')
      
      let filter = { 
        ...this.defaultFilter,
        status_id:this.status_id, 
        limit:    this.limit, 
        query:    this.query,
        order:    this.order,
        range:    this.range,
        page:     this.page,
        context:  this.context,
        deadline:     this.deadline,
        area_id:  this.area_id,
        solicitations: this.solicitations
      }

      this.demandTaskService.getAll(filter).subscribe({
        next: (result: any) => {
          console.log('DEMANDAS', result)
          this.demandsTasks = result
          console.log(result)
          this.limit ? this.demandsTasks.data = this.demandsTasks.data.slice(0, this.limit) : ''
          this.skeleton = false
          if(this.pagination.length == 0) {
            for (let i = 1; i <= result.last_page; i++) {
              this.pagination.push(i)
            }
          }
        },
        error: err => console.log(err)
      })
    })
  }

  

  toggleOptions(index: number): void {
    this.selectedRowIndex = this.selectedRowIndex === index ? null : index;
  }

  deleteDemand(demand_id:number) { 
    this.demandsService.deleteById(demand_id).subscribe({
      next: (result) => console.log(result),
      error: (err) => console.log(err)
    })
  }


  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event): void {
    const target = event.target as HTMLElement;

    // Verifica se o clique foi dentro de um botão de opções ou da lista de opções
    if (!target.closest('.btn-options') && !target.closest('.options-box')) {
      this.selectedRowIndex = null;
    }
  }

  getByAreaId(area_id: any) { 
    this.area_id = area_id
  }

  getByDate(event:any) {
    this.deadline = event.target.value;
    this.getItems();
  }

  searchItens(query: any) {
    this.query = query
    this.getItems()
  }

  orderItens(order: any) {
    this.order = order
    this.getItems()
  }

  goPage(p: number) {
    this.page = p
    this.getItems()
  }

  getItems() {
    switch(this.context) { 
      case 'demand':
        this.getDemands()
      break;
      case 'task':
        this.getDemandsTasks();
      break;
    }
  }

  copyToClipboard() {
    this.clipboard.copy(`${this.sharedUrl}`);
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      text: 'Copiado para a área de transfência',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  showDemand(demand_id: number, content: any) {
    this.demand_id = demand_id
    this.modalService.open(content, { windowClass: 'custom-modal', size: 'xl' });
  }

  newDemand(content: any) {
    this.modalService.open(content, { size: 'lg' });
  }

  demandHeader(data: any) {
    this.header = data
  }

  getEmoji(emoji_id:number) {
    if (!emoji_id) return null;
    let emoji = emojis.filter((e:any) => {
      return e.id == emoji_id
    })[0]
    return emoji.emoji
  }

  addOpacity(color: string, opacity: number): string {

    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
  
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

}
