<div class="emoji-selector bg-transaprent">
    <button class="btn-emoji-text m-0 p-0" (click)="toggleEmojiList()" *ngIf="!emoji && ['screening'].includes(currentUser.group.name)">
       <img src="assets/argon-dashboard-master/assets/img/claro/icons/plus.svg" alt="">
    </button>
    <button class="btn btn-emoji bg-transparent m-0 px-1 py-0" (click)="['screening'].includes(currentUser.group.name) ? toggleEmojiList() : ''" *ngIf="emoji">
        {{ emoji.emoji }} 
    </button>
    <div class="emoji-list {{positionList}}" *ngIf="show">
      <div *ngFor="let emoji of emojis" class="emoji-item" (click)="select(emoji)">
        {{ emoji.emoji }}
      </div>
    </div>
</div>