<ngx-skeleton-loader 
*ngIf="skeleton"
count="5" 
appearance="line" 
[theme]="{height: '45px'}">
</ngx-skeleton-loader>

<div class="card z-index-2 h-100" *ngIf="!skeleton">
  <div class="card-header py-3 bg-transparent table-filter d-flex align-items-center">
      <h6 class="table-title mb-0">{{ title }} ({{ (demands?.total || demandsTasks?.total)}})</h6>
      <button class="btn btn-primary ms-3 mb-0 border-radius-4 add-new" (click)="newDemand(create_demand)" *ngIf="addNew">+ Nova Demanda</button>

      <div class="filters ms-auto">
        <button class="btn-filters btn-filters-square me-2 border-radius-8" (click)="showFiltersBox = !showFiltersBox" [class.show]="showFiltersBox"><img src="assets/argon-dashboard-master/assets/img/claro/icons/filters.svg" alt=""></button>
        <div class="box-filters" *ngIf="showFiltersBox">
          <span class="title">Filtrar por</span>

          <div class="form-group d-flex justify-content-between align-items-center mb-0">
            <label>Prazo</label>
            <select class="form-control w-60" [(ngModel)]="range">
              <option value="all">Todas</option>
              <option value="late">Atrasadas</option>
              <option value="on_time">No prazo</option>
            </select>
          </div>

          <div class="form-group d-flex justify-content-between align-items-center mb-0">
            <label>Data</label>
            <input type="date" class="form-control w-60" placeholder="Filtre por data" [(ngModel)]="deadline">
          </div>

          <div class="form-group d-flex justify-content-between align-items-center mb-0">
            <label>Status</label>
            <select class="form-control w-60" [(ngModel)]="status_id">
              <option value="">Todos</option>
              <option [value]="s.id" *ngFor="let s of status?.data">{{s.name}}</option>
            </select>
          </div>

          
          <!-- <div class="form-group d-flex justify-content-between align-items-center">
            <label>Área</label>
            <select name="" id="" class="form-control w-60" #area [(ngModel)]="area_id">
              <option value="0">Selecione</option>
              <option [value]="area.id" *ngFor="let area of areas">{{ area.name }}</option>
            </select>
          </div> -->

          <button class="btn-action btn-filter btn-primary" (click)="getItems()">Filtrar</button>
          <button class="text-white bg-transparent border-0 small" (click)="resetFilters()">Limpar</button>
        </div>
      </div>
      
      <div class="input-group search w-auto">
        <input type="text"  class="form-control" placeholder="Pesquisar..." [(ngModel)]="query" (input)="!query ? getItems() : ''" (keyup.enter)="getItems()" #search>
        <button class="btn btn-search p-2 m-0 shadow-none" type="button"><i class="fas fa-search fa-sm"></i></button>
      </div>
  </div>
  <hr class="m-0 bg-secondary w-auto mx-3">
  <div class="card-body p-3 pb-4">  
    <div class="table-responsive p-0" *ngIf="demands">
        <table class="table demand align-items-center mb-0" *ngIf="demands?.data?.length > 0 && context === 'demand'">
          <thead>
            <tr>
              <th class="text-center w-60">Demanda</th>
              <th class="text-center">Prazo</th>
              <th class="text-center" *ngIf="!['requester'].includes(currentUser.group.name)">Tarefas</th>
              <!-- <th></th> -->
              <th class="text-center" *ngIf="!['requester'].includes(currentUser.group.name)">Envolvidos</th>
              <th class="text-center">Status da demanda</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of demands.data; let i = index">
              <td>
                <div class="demand d-flex align-items-center px-2 py-1">
                  <span class="emoji me-2" *ngIf="d.emoji_id && !['requester'].includes(currentUser.group.name)">{{getEmoji(d.emoji_id)}}</span>
                  <h6 class="mb-0 text-sm py-2 pointer demand-name text-underline-hover" (click)="showDemand(d.id, content)">
                    <p class="title">
                      <span class="project-code me-2" *ngIf="d.project?.code && d?.item_id">{{ d.project?.code }}.{{ d?.item_id }}</span>
                      <span class="demand-name">{{ d.name }}</span>
                      <span class="project-name d-block" *ngIf="d.project?.name">{{d?.project?.name}}</span>
                    </p>
                  </h6>
                </div>
              </td>
              <td class="align-middle text-center">
                <span>{{ d.deadline | date: "dd/MM/yyyy" }}</span>
              </td>
              <td class="align-middle text-center" *ngIf="!['requester'].includes(currentUser.group.name)">
                <span>
                  <img src="assets/argon-dashboard-master/assets/img/claro/icons/tasks_list.svg" alt="" width="20" class="me-2">
                  {{d.tasks_count}}
                </span>
              </td>
              <td class="align-middle text-center text-sm" *ngIf="!['requester'].includes(currentUser.group.name)">
                <div class="avatar-group ms-auto">
                  <div class="avatar avatar-lg avatar-sm rounded-circle" *ngFor="let u of d.involved_users">
                    <img [alt]="u?.name" [title]="u?.name" [ngbTooltip]="u?.name" [src]="u?.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'">
                  </div>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <div class="badge p-0 bg-white">
                  <span class="badge-status" 
                  [ngStyle]="{
                    'background-color': addOpacity(d.status.color, 0.1),
                    'color': d.status.color
                  }">
                    {{ d.status.name }}
                  </span>
                </div>
              </td>
              <td class="align-middle text-center position-relative">
                <button class="btn btn-options mb-0" (click)="toggleOptions(i)">
                  <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                </button>
                <ul class="options-box" [class.d-none]="selectedRowIndex !== i">
                  <li  (click)="showDemand(d.id, content)"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
<path d="M8.51242 2.91162L0.928257 10.5044C0.890156 10.5428 0.86242 10.591 0.849533 10.6429L0.00877784 14.0207C-0.0164364 14.1225 0.0135405 14.2305 0.0875023 14.3048C0.143534 14.3609 0.220017 14.392 0.298181 14.392C0.321995 14.392 0.346649 14.3889 0.370462 14.3831L3.74441 13.5414C3.79708 13.5282 3.8447 13.5007 3.88281 13.4626L11.4678 5.87041L8.51242 2.91162Z" fill="white"/>
<path d="M13.3468 1.87646L12.5027 1.03145C11.9385 0.466613 10.9551 0.467174 10.3914 1.03145L9.35738 2.06661L12.3128 5.02512L13.3468 3.98996C13.6287 3.70783 13.7839 3.33258 13.7839 2.93321C13.7839 2.53384 13.6287 2.1586 13.3468 1.87646Z" fill="white"/>
</svg> Editar</li>
                  <li  (click)="showDemand(d.id, content)"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
<path d="M9 3.38281C4.90909 3.38281 1.41545 5.91941 0 9.5C1.41545 13.0806 4.90909 15.6172 9 15.6172C13.095 15.6172 16.5845 13.0806 18 9.5C16.5845 5.91941 13.095 3.38281 9 3.38281ZM9 13.5781C6.74182 13.5781 4.90909 11.7511 4.90909 9.5C4.90909 7.24887 6.74182 5.42188 9 5.42188C11.2582 5.42188 13.0909 7.24887 13.0909 9.5C13.0909 11.7511 11.2582 13.5781 9 13.5781ZM9 7.05313C7.64591 7.05313 6.54545 8.15014 6.54545 9.5C6.54545 10.8499 7.64591 11.9469 9 11.9469C10.3541 11.9469 11.4545 10.8499 11.4545 9.5C11.4545 8.15014 10.3541 7.05313 9 7.05313Z" fill="white"/>
</svg> Visualizar</li>
                  <li (click)="deleteDemand(d.id)"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
<path d="M15.1923 2.61538H11.9234V1.96154C11.9228 1.44149 11.7159 0.942903 11.3482 0.575171C10.9805 0.207438 10.4819 0.000587961 9.96182 0H6.03874C5.51869 0.000587961 5.02011 0.207438 4.65238 0.575171C4.28464 0.942903 4.07779 1.44149 4.07721 1.96154V2.61538H0.807655C0.634244 2.61538 0.467936 2.68427 0.345316 2.80689C0.222696 2.92951 0.153809 3.09582 0.153809 3.26923C0.153809 3.44264 0.222696 3.60895 0.345316 3.73157C0.467936 3.85419 0.634244 3.92308 0.807655 3.92308H1.4615V15.6923C1.4619 16.039 1.5998 16.3714 1.84495 16.6165C2.09011 16.8617 2.42249 16.9996 2.76919 17H13.2307C13.5774 16.9996 13.9098 16.8617 14.155 16.6165C14.4001 16.3714 14.538 16.039 14.5384 15.6923V3.92308H15.1923C15.3657 3.92308 15.532 3.85419 15.6546 3.73157C15.7772 3.60895 15.8461 3.44264 15.8461 3.26923C15.8461 3.09582 15.7772 2.92951 15.6546 2.80689C15.532 2.68427 15.3657 2.61538 15.1923 2.61538ZM6.69259 12.4231C6.69259 12.5965 6.6237 12.7628 6.50108 12.8854C6.37846 13.008 6.21215 13.0769 6.03874 13.0769C5.86533 13.0769 5.69903 13.008 5.57641 12.8854C5.45379 12.7628 5.3849 12.5965 5.3849 12.4231V7.19231C5.3849 7.0189 5.45379 6.85259 5.57641 6.72997C5.69903 6.60735 5.86533 6.53846 6.03874 6.53846C6.21215 6.53846 6.37846 6.60735 6.50108 6.72997C6.6237 6.85259 6.69259 7.0189 6.69259 7.19231V12.4231ZM10.6157 12.4231C10.6157 12.5965 10.5468 12.7628 10.4242 12.8854C10.3015 13.008 10.1352 13.0769 9.96182 13.0769C9.78841 13.0769 9.6221 13.008 9.49948 12.8854C9.37686 12.7628 9.30797 12.5965 9.30797 12.4231V7.19231C9.30797 7.0189 9.37686 6.85259 9.49948 6.72997C9.6221 6.60735 9.78841 6.53846 9.96182 6.53846C10.1352 6.53846 10.3015 6.60735 10.4242 6.72997C10.5468 6.85259 10.6157 7.0189 10.6157 7.19231V12.4231ZM10.6157 2.61538H5.3849V1.96154C5.3851 1.78819 5.45405 1.622 5.57662 1.49942C5.6992 1.37684 5.86539 1.30789 6.03874 1.30769H9.96182C10.1352 1.30789 10.3014 1.37684 10.4239 1.49942C10.5465 1.622 10.6155 1.78819 10.6157 1.96154V2.61538Z" fill="white"/>
</svg> Excluir</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center d-block py-5" *ngIf="demands?.data?.length == 0 && context === 'task'">
          <small>{{ emptyTableText }}</small>
          <div class="d-flex justify-content-center">
            <ng-lottie [options]="options" height="300px" (animationCreated)="animationCreated($event)"></ng-lottie>
          </div>
        </div>
    </div>
    <div class="table-responsive p-0" *ngIf="demandsTasks">
        <table class="table demand-task align-items-center mb-0" *ngIf="demandsTasks?.data?.length > 0 && context === 'task'">
          <thead>
            <tr>
              <th class="text-center">Demanda</th>
              <th class="text-center">Tarefa</th>
              <th class="text-center">Prazo</th>
              <th class="text-center">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let demandTask of demandsTasks.data; let i = index" class="pointer">
              <td>
                <div class="demand d-flex align-items-center px-2 py-1">
                  <span class="emoji me-2" *ngIf="demandTask.demand.emoji_id">{{getEmoji(demandTask.demand.emoji_id)}}</span>
                  <h6 class="mb-0 text-sm py-2 pointer demand-name" (click)="showDemand(demandTask.demand.id, content)">
                    <p class="title text-xs">
                      <span class="project-code me-2" *ngIf="demandTask.demand.project?.code && demandTask.demand?.item_id">{{ demandTask.demand.project?.code }}.{{ demandTask.demand?.item_id }}</span>
                      <span class="demand-name">{{ demandTask.demand.name }}</span>
                      <span class="project-name d-block" *ngIf="demandTask.demand.project?.name">{{demandTask.demand?.project?.name}}</span>
                    </p>
                  </h6>
                </div>
              </td>
              <td class="align-middle text-center">
                <span>{{ demandTask.task.name }}</span>
              </td>
              <td class="align-middle text-center">
                <span [class.text-secondary]="!demandTask.expired" [class.text-danger]="demandTask.expired">{{ demandTask.due_date | date: "dd/MM/yyyy" }}</span>
              </td>
              <td class="align-middle text-center text-sm">
                <div class="badge p-0 bg-white">
                  <span class="badge-status" [style.background]="demandTask.status.color">
                    {{ demandTask.status.name }}
                  </span>
                </div>
              </td>
              <td class="align-middle text-center position-relative">
                <button class="btn btn-options mb-0" (click)="toggleOptions(i)">
                  <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                </button>
                <ul class="options-box" [class.d-none]="selectedRowIndex !== i">
                  <li  (click)="showDemand(demandTask.demand.id, content)">
                    <span  class="option-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                        <path d="M8.51242 2.91162L0.928257 10.5044C0.890156 10.5428 0.86242 10.591 0.849533 10.6429L0.00877784 14.0207C-0.0164364 14.1225 0.0135405 14.2305 0.0875023 14.3048C0.143534 14.3609 0.220017 14.392 0.298181 14.392C0.321995 14.392 0.346649 14.3889 0.370462 14.3831L3.74441 13.5414C3.79708 13.5282 3.8447 13.5007 3.88281 13.4626L11.4678 5.87041L8.51242 2.91162Z" fill="white"/>
                        <path d="M13.3468 1.87646L12.5027 1.03145C11.9385 0.466613 10.9551 0.467174 10.3914 1.03145L9.35738 2.06661L12.3128 5.02512L13.3468 3.98996C13.6287 3.70783 13.7839 3.33258 13.7839 2.93321C13.7839 2.53384 13.6287 2.1586 13.3468 1.87646Z" fill="white"/>
                    </svg>
                    </span> Editar </li>
                    <li  (click)="showDemand(demandTask.demand.id, content)"><span class="option-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                        <path d="M9 3.38281C4.90909 3.38281 1.41545 5.91941 0 9.5C1.41545 13.0806 4.90909 15.6172 9 15.6172C13.095 15.6172 16.5845 13.0806 18 9.5C16.5845 5.91941 13.095 3.38281 9 3.38281ZM9 13.5781C6.74182 13.5781 4.90909 11.7511 4.90909 9.5C4.90909 7.24887 6.74182 5.42188 9 5.42188C11.2582 5.42188 13.0909 7.24887 13.0909 9.5C13.0909 11.7511 11.2582 13.5781 9 13.5781ZM9 7.05313C7.64591 7.05313 6.54545 8.15014 6.54545 9.5C6.54545 10.8499 7.64591 11.9469 9 11.9469C10.3541 11.9469 11.4545 10.8499 11.4545 9.5C11.4545 8.15014 10.3541 7.05313 9 7.05313Z" fill="white"/>
                      </svg></span> Visualizar</li>
                      <li (click)="deleteDemand(demandTask.demand.id)"><span class="option-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path d="M15.1923 2.61538H11.9234V1.96154C11.9228 1.44149 11.7159 0.942903 11.3482 0.575171C10.9805 0.207438 10.4819 0.000587961 9.96182 0H6.03874C5.51869 0.000587961 5.02011 0.207438 4.65238 0.575171C4.28464 0.942903 4.07779 1.44149 4.07721 1.96154V2.61538H0.807655C0.634244 2.61538 0.467936 2.68427 0.345316 2.80689C0.222696 2.92951 0.153809 3.09582 0.153809 3.26923C0.153809 3.44264 0.222696 3.60895 0.345316 3.73157C0.467936 3.85419 0.634244 3.92308 0.807655 3.92308H1.4615V15.6923C1.4619 16.039 1.5998 16.3714 1.84495 16.6165C2.09011 16.8617 2.42249 16.9996 2.76919 17H13.2307C13.5774 16.9996 13.9098 16.8617 14.155 16.6165C14.4001 16.3714 14.538 16.039 14.5384 15.6923V3.92308H15.1923C15.3657 3.92308 15.532 3.85419 15.6546 3.73157C15.7772 3.60895 15.8461 3.44264 15.8461 3.26923C15.8461 3.09582 15.7772 2.92951 15.6546 2.80689C15.532 2.68427 15.3657 2.61538 15.1923 2.61538ZM6.69259 12.4231C6.69259 12.5965 6.6237 12.7628 6.50108 12.8854C6.37846 13.008 6.21215 13.0769 6.03874 13.0769C5.86533 13.0769 5.69903 13.008 5.57641 12.8854C5.45379 12.7628 5.3849 12.5965 5.3849 12.4231V7.19231C5.3849 7.0189 5.45379 6.85259 5.57641 6.72997C5.69903 6.60735 5.86533 6.53846 6.03874 6.53846C6.21215 6.53846 6.37846 6.60735 6.50108 6.72997C6.6237 6.85259 6.69259 7.0189 6.69259 7.19231V12.4231ZM10.6157 12.4231C10.6157 12.5965 10.5468 12.7628 10.4242 12.8854C10.3015 13.008 10.1352 13.0769 9.96182 13.0769C9.78841 13.0769 9.6221 13.008 9.49948 12.8854C9.37686 12.7628 9.30797 12.5965 9.30797 12.4231V7.19231C9.30797 7.0189 9.37686 6.85259 9.49948 6.72997C9.6221 6.60735 9.78841 6.53846 9.96182 6.53846C10.1352 6.53846 10.3015 6.60735 10.4242 6.72997C10.5468 6.85259 10.6157 7.0189 10.6157 7.19231V12.4231ZM10.6157 2.61538H5.3849V1.96154C5.3851 1.78819 5.45405 1.622 5.57662 1.49942C5.6992 1.37684 5.86539 1.30789 6.03874 1.30769H9.96182C10.1352 1.30789 10.3014 1.37684 10.4239 1.49942C10.5465 1.622 10.6155 1.78819 10.6157 1.96154V2.61538Z" fill="white"/>
                      </svg></span> Excluir</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center d-block py-5" *ngIf="demands?.data?.length == 0 || demandsTasks?.data?.length == 0">
          <small>{{ emptyTableText }}</small>
          <div class="d-flex justify-content-center">
            <ng-lottie [options]="options" height="300px" (animationCreated)="animationCreated($event)"></ng-lottie>
          </div>
        </div>
    </div>
    <nav aria-label="Page navigation example" *ngIf="demands.last_page > 1 && !limit">
        <ul class="pagination justify-content-end mt-4">
            <li class="page-item active" *ngFor="let p of pagination"
                [class.active]="demands.current_page == p">
                <a class="page-link" (click)="goPage(p)" href="javascript:;">{{ p }}</a>
            </li>
        </ul>
    </nav>
  </div>
</div>

<ng-template #content let-modal>
  <app-demands-form #demand [demand_id]="demand_id" (demandHeader)="demandHeader($event)" (cancelModal)="modal.dismiss('Cross click')" (updateCalendar)="getDemands()" [modal]="true"></app-demands-form>
</ng-template>

<ng-template #create_demand let-modal>
  <app-requester-demands-form (close)="modal.dismiss('Cross click')"></app-requester-demands-form>
</ng-template>