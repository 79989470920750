<div class="card">
    <div class="card-header py-3">
        <div class="row align-items-center" *ngIf="!skeleton">
            <div class="col">
                <h6 class="table-title">
                    <a [routerLink]="'/' + currentUser.group.name + '/projects'"><i class="fa-solid fa-arrow-left me-3"></i></a>
                    <span class="project-code me-2">{{project.code}}</span> 
                    <span class="project-name">{{project.name}}</span>
                </h6>
            </div>
            <div class="col-auto ps-5" style="border-left: 1px solid gray">
                <p class="mb-0 text-end"><small>Responsável: {{project.user.name}}</small></p>
                <p class="mb-0 text-end"><small>Projeto criado em: {{project.created_at | date: 'dd/MM/yyyy'}}</small></p>
            </div>
        </div>
    </div>
    <hr class="m-0 bg-secondary w-auto mx-3">
    <div class="card-body p-0">
        <app-demand-table context="demand" [defaultFilter]="filter" title="DEMANDAS" [addNew]="true"></app-demand-table>
    </div>
</div>

