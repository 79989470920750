import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  getAll(filter:any = null) {
    return this.post(`users/filter`, filter)
  }

  save(data: FormData) {
    return this.post(`users`, data)
  }

  register(data: FormData) {
    return this.post(`users/register`, data)
  }

  validatePin(data:any){
    console.log(data);
    return this.post(`pin/validate`, data);
  }

  updateUser(data: FormData) {
    return this.post(`users`, data)
  }

  getById(id: number) {
    return this.get(`users/${id}`)
  }

  getByHashId(hashid: number) {
    return this.get(`users/h/${hashid}`)
  }

  getByGroup(name: string, filter:any = {}) {
    return this.post(`users/group/${name}`, filter)
  }

  getAllGroups() {
    return this.get(`groups`)
  }

  deleteUser(id: any) {
    return this.delete(`users/${id}`)
  }

  deleteById(id: any) {
    return this.delete(`users/${id}`)
  }
  
  getLocalUser() { 
    let localUser = localStorage.getItem('user')?.toString()
    if(localUser) return JSON.parse(localUser)
  }

  getFields(model_id:number, model_type:string, filter:any) { 
    return this.post(`fields/values/model/${model_type}/${model_id}/filter`, filter)
  }

  
}

