import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TaskService } from '../../services/demands/task.service';
import { StatusService } from '../../services/demands/status.service';
import { UserService } from '../../services/user-management/user.service';
import { DemandTasksService } from '../../services/demands/demand-tasks.service';
import { TimesheetService } from '../../services/demands/timesheet.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  @Input() demand_id: number = 0
  @Input() max:any = null
  @Input() canAdd:boolean = true;
  demandTasks:any = [];
  tasks:  any  = []
  users:  any  = []
  activeTaskIndex: number | null = null;
  skeleton = true
  @Output() hasOpenTasks = new EventEmitter<boolean>();
  edit = false;
  currentUser:any;
  status:any = null

  constructor(
    private demandTaskService: DemandTasksService,
    private tasksService: TaskService,
    private usersService: UserService,
    public timesheetService: TimesheetService,
    private statusService: StatusService,
    private eRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.currentUser = this.usersService.getLocalUser();
    this.getDemandTasks();
    this.getUsers();
    this.getTasks();
    this.getStatus();

    this.timesheetService.endTime$.subscribe((demandTaskId) => {
      if(demandTaskId) { 
        this.demandTaskService.getById(demandTaskId).subscribe({
          next: (result: any) => {
            console.log(result);
            result.timesheet_active = this.isDemandTaskTimesheetActive(demandTaskId);

            let index = this.demandTasks.findIndex(task => task.id === demandTaskId);
      
            if (index !== -1) this.demandTasks[index] = result;
          },
          error: (err: any) => console.log(err)
        });
      }
    });
  }

  getStatus() { 
    this.statusService.getAll({
      context: 'task'
    }).subscribe({
      next: (result:any) => {  
        this.status = result.data; 
        console.log(result)
      },
      error: (err) => console.log(err)
    })
  }
  
  getTasks() {
    this.tasksService.getAll().subscribe({
      next: (result: any) => {
        this.tasks = result
      },
      error: (err) => console.log(err)
    })
  }

  checkHasOpenTasks() { 
    let hasOpenTasks = this.demandTasks.some((t: { done: boolean }) => !t.done);
    console.log(hasOpenTasks);
    this.hasOpenTasks.emit(hasOpenTasks);
  }

  getDemandTasks() {
    this.demandTaskService.getByDemandId(this.demand_id).subscribe({
      next: (result: any) => {
        console.log(result);
        this.demandTasks = result;
        this.demandTasks.forEach((demandTask:any) => {
            demandTask.timesheet_active = this.isDemandTaskTimesheetActive(demandTask.id);
        });
        this.checkHasOpenTasks();
        this.skeleton = false
      },
      error: (err) => console.log(err)
    })
  }

  done(demandTask:any) { 
    this.demandTaskService.done(demandTask.id).subscribe({
      next: (result: any) => {
        this.getDemandTasks();
      },
      error: (err) => console.log(err)
    })
  }

  getUsers() {
    this.usersService.getByGroup('analyst', {area_id: this.currentUser.area_id}).subscribe({
      next: (result: any) => {
        this.users = result
      },
      error: (err) => console.log(err)
    })
  }

  addTask() {
      this.skeleton = true
      let task: any = {
        demand_id: this.demand_id,
        due_date: new Date(),
        done: 0,
        timesheets_sum_duration: 0,
        new: true,
      }
  
      this.demandTasks.push(task);
  }

  teste(log) { 
    console.log(log)
  }

  isDemandTaskTimesheetActive(demandTaskId:number) {
    let timesheet:any = localStorage.getItem('timesheet');
    if(timesheet) {
      timesheet = JSON.parse(timesheet);
      console.log(timesheet)
      return timesheet.demand_task_id == demandTaskId;
    } else 
      return false;
  }
  
  startTimesheet(demandTaskId: number) { 
      if(!localStorage.getItem('timesheet')) {
        this.timesheetService.setStartTime(demandTaskId);
        this.getDemandTaskById(demandTaskId);
    } else {
      console.log('tem timesheet ativo');
      return;
    }
  }

  getDemandTaskById(demandTaskId:number) {
    this.demandTaskService.getById(demandTaskId).subscribe({
      next: (result: any) => {
        console.log(result);
        result.timesheet_active = this.isDemandTaskTimesheetActive(demandTaskId);
            
        let index = this.demandTasks.findIndex(demandTask => demandTask.id == demandTaskId);
        console.log(index)
  
        if (index !== -1) this.demandTasks[index] = result;
      },
      error: (err) => console.log(err)
    });
  }

  save(demandTask: any) {
    console.log(demandTask);
    this.demandTaskService.save(this.demand_id, demandTask).subscribe({
      next: (result) => {
        this.getDemandTasks()
      },
      error: (err) => console.log(err)
    })
  }

  deleteTask(id: number) {
    Swal.fire({
      icon: 'warning',
      text: `Deseja excluir a tarefa?`,
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c',
    }).then(result => {
      if (result.value) {
        this.demandTaskService.deleteById(id).subscribe({
          next: () => {
            this.getDemandTasks()
          },
          error: (err) => console.log(err)
        })
      }
    })
  }

  deleteFromArray(i) { 
    Swal.fire({
      icon: 'warning',
      text: `Deseja excluir a tarefa?`,
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c',
    }).then(result => {
      if (result.value) {
        this.demandTasks.splice(i, 1);
      }
    })
  }

  updateStatus(task: any, status: any) {
    this.tasksService.updateStatus({
      id: task.id,
      identifier: status.identifier,
      context: 'task'
    }).subscribe({
      next: (result) => this.getDemandTasks(),
      error: (err) => console.log(err)
    })
  }

  toggleUserList(index: number, event: MouseEvent) {
    event.stopPropagation();  // Evita que o clique no avatar feche a lista

    if (this.activeTaskIndex === index) {
      this.activeTaskIndex = null; // Se já estiver visível, oculta a lista
    } else {
      this.activeTaskIndex = index; // Exibe a lista apenas para o item clicado
    }
  }

  // Ouve cliques no documento inteiro
  @HostListener('document:click', ['$event'])
  clickOut(event: Event) {
    // Verifica se o clique foi fora do componente
    if (this.activeTaskIndex !== null && !this.eRef.nativeElement.contains(event.target)) {
      this.activeTaskIndex = null;  // Fecha a lista
    }
  }

}
