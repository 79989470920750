<!-- <div class="container-fluid px-0 table-filter">
    <div class="row g-3 align-items-center table-filter mb-3 filters">
        <div class="col-2 d-flex justify-content-end">
            <input type="date" class="form-control" placeholder="Filtre por data" [(ngModel)]="deadline">
        </div>
        <div class="col-3 d-flex justify-content-end">
            <select name="" id="" class="form-control" [(ngModel)]="user_id">
                <option value="">Filtre por colaborador</option>
                <option [value]="u.id" *ngFor="let u of users">{{u.name}}</option>
            </select>
        </div>
    </div>
</div> -->
<div class="card kanban-box-container">
    <div class="card-header py-3 bg-transparent table-filter d-flex align-items-center">
        <h6 class="table-title mb-0">{{ title }} ({{ demandsCount }})</h6>
        <button class="btn btn-primary ms-3 mb-0 border-radius-4 add-new" (click)="newDemand(content)" *ngIf="addNew">+ Nova Demanda</button>
  
        <div class="filters ms-auto">
          <button class="btn-filters btn-filters-square me-2 border-radius-8" (click)="showFiltersBox = !showFiltersBox" [class.show]="showFiltersBox"><img src="assets/argon-dashboard-master/assets/img/claro/icons/filters.svg" alt=""></button>
          <div class="box-filters" *ngIf="showFiltersBox">
            <span class="title">Filtrar por</span>
            <!-- <div class="form-group d-flex align-items-center mb-0">
              <label>Ordenar por</label>
              <select class="form-control w-60" [(ngModel)]="order">
                  <option value="desc">Mais recentes</option>
                  <option value="asc">Mais antigas</option>
                </select>
            </div> -->
            <div class="form-group d-flex justify-content-between align-items-center mb-0">
                <label>Prazo</label>
                <select class="form-control w-60" [(ngModel)]="range">
                    <option value="all">Todas</option>
                    <option value="late">Atrasadas</option>
                    <option value="on_time">No prazo</option>
                </select>
            </div>

            <div class="form-group d-flex justify-content-between align-items-center mb-0">
                <label>Data</label>
                <input type="date" class="form-control w-60" placeholder="Filtre por data" [(ngModel)]="deadline">
              </div>

            <!-- <div class="form-group d-flex justify-content-between align-items-center mb-0" *ngIf="status">
                <label>Status</label>
                <select class="form-control w-60" [(ngModel)]="status_id">
                    <option value="">Todos</option>
                  <option [value]="s.id" *ngFor="let s of status.data">{{s.name}}</option>
                </select>
            </div> -->
  
            <!-- <div class="form-group d-flex align-items-center" *ngIf="['leadership', 'screening'].includes(currentUser.group.name)">
                <label>Área</label>
                <select name="" id="" class="form-control w-60" #area [(ngModel)]="area_id">
                  <option value="0">Selecione</option>
                  <option [value]="area.id" *ngFor="let area of areas">{{ area.name }}</option>
                </select>
            </div> -->

            <button class="btn-action btn-filter btn-primary" (click)="getKanban()">Filtrar</button>
            <button class="text-white bg-transparent border-0 small" (click)="resetFilters()">Limpar</button>
          </div>
        </div>
        
        <div class="input-group search w-auto">
          <input type="text"  class="form-control" placeholder="Pesquisar..." [(ngModel)]="query" (input)="!query ? getKanban() : ''" (keyup.enter)="getKanban()" #search>
          <button class="btn btn-search p-2 m-0 shadow-none" type="button"><i class="fas fa-search fa-sm"></i></button>
        </div>
    </div>
    <div class="card-body pb-3 pt-2">
        <!-- <div class="row" *ngIf="skeleton">
            <div class="col-3" *ngFor="let i of [1,2,3,4]">
                <ngx-skeleton-loader 
                    count="1" 
                    [theme]="{height: '708px', width: '100%',borderRadius: '1rem'}">
                </ngx-skeleton-loader>
            </div>
        </div> -->

        <div class="kanban-container">
            <div class="row content g-3" #kanbanContent>
                <ng-container *ngIf="!skeleton">
                    <div class="col-custom" *ngFor="let s of status">
                        <h6 class="kanban-title text-center text-uppercase my-3" [style.color]="s.color">
                            <img src="assets/argon-dashboard-master/assets/img/claro/icons/status/{{s.icon}}" alt="" class="me-2" *ngIf="s.icon">
                            {{s.name}} ({{ s.demands.length ?? 0 }})
                        </h6>
                        <div class="card status card-body p-3 shadow-none">
                            <div
                                class="item-drop"
                                autoScrollDisabled="true">
                                <div class="card kanban-card pointer z-index-2 item"
                                    [style.border]="s.color"
                                    (click)="openDemandPopup ? showDemand(d.id, content) : ''" *ngFor="let d of s.demands">
                                    <div class="card-body py-2 px-3">
                                        <div class="demand d-flex align-items-center">
                                            <!-- <span class="emoji me-2">{{getEmoji(d.emoji_id)}}</span> -->
                                            <h6 class="mb-0 text-sm py-2 pointer demand-name">
                                                <p class="title text-xs">
                                                <span class="project-name mb-1 fw-bold d-block" *ngIf="d.project?.name">{{d?.project?.name}}</span>
                                                <span class="project-code me-2" *ngIf="d.project?.code && d?.item_id">{{ d.project?.code }}.{{ d?.item_id }}</span>
                                                <span class="demand-name">{{ d?.name }}</span>
                                                </p>
                                            </h6>
                                        </div>
                                        <p class="mb-0 deadline mt-2">
                                            <img src="assets/argon-dashboard-master/assets/img/claro/icons/calendar.svg" alt="" width="15">
                                            {{ d.created_at | date : 'dd/MM/yyyy' }}
                                        </p>
                                        <div class="d-flex align-items-center justify-content-between mt-4" style="line-height: normal">
                                            <div class="avatar-group" *ngIf="d.involved_users.length > 0">
                                                <div class="avatar avatar-lg avatar-sm rounded-circle" *ngFor="let u of d.involved_users">
                                                    <img alt="Image placeholder" [title]="u.name" [ngbTooltip]="u.name" [src]="u.avatar || 'assets/argon-dashboard-master/assets/img/default-user.png'">
                                                </div>
                                                <!-- <div class="avatar avatar-lg avatar-sm rounded-circle m-0">
                                                    <img alt="Image placeholder" [title]="d.user?.name" [ngbTooltip]="d.user?.name" [src]="d.user?.avatar || 'assets/argon-dashboard-master/assets/img/default-user.png'">
                                                </div> -->
                                            </div>
                                            <div class="text-end d-flex align-items-center ms-auto">
                                                <div class="emoji me-2">{{getEmoji(d?.emoji_id)}}</div>
                                            </div>
                                            <div class="text-end d-flex align-items-center comments">
                                                <img src="assets/argon-dashboard-master/assets/img/claro/icons/chats_count.svg" width="19">
                                                <span class="text-xs ms-1">{{ d?.chats_count }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="skeleton">
                    <div class="col-3" *ngFor="let i of [1,2,3,4]">
                        <ngx-skeleton-loader 
                            count="1" 
                            [theme]="{height: '708px', width: '100%',borderRadius: '1rem'}">
                        </ngx-skeleton-loader>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <app-demands-form #demand [demand_id]="demand_id" (cancelModal)="modal.dismiss('Cross click')" [modal]="true"></app-demands-form>
</ng-template>