<h5 class="mb-3">TAREFAS</h5>

<div class="tasks">
    <div class="card shadow-none task-row task-row-create position-relative" 
        *ngFor="let demandTask of demandTasks; let i = index"
        [style.backgroundColor]="demandTask.status?.color || '#343f5a'"
        [class.disabled]="demandTask.done">
        <div class="card-body py-0 px-0">
            <div class="w-100 d-flex align-items-center py-2 px-3" [style.backgroundColor]="demandTask.status?.color ? (demandTask.status?.color + '1A') : '#343f5a' + '1A'">
                <div class="d-flex align-items-center justify-content-between w-100">
                    <div class="d-flex align-items-center">
                        <input type="radio" 
                        name="done-{{ demandTask?.id }}" 
                        class="form-check-input me-2"
                        (change)="done(demandTask)" 
                        [disabled]="demandTask.done" 
                        [checked]="demandTask.done"
                        style="width: 18px; height: 18px; cursor: pointer"
                        *ngIf="currentUser?.id == demandTask.user?.id">
                        <div class="box-users">
                            <img [src]="demandTask.user?.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'" class="avatar"
                                [title]="demandTask.user?.name || ''" 
                                (click)="!demandTask.user ? toggleUserList(i, $event): ''">

                            <div class="list shadow" *ngIf="activeTaskIndex === i && !demandTask.done">
                                <h5 class="p-2">Envolvidos</h5>
                                <div class="item d-flex p-2 align-items-center pointer" 
                                    *ngFor="let u of users"
                                    (click)="demandTask.user = u; demandTask.task = []; toggleUserList(i, $event)">
                                    <img [src]="u.avatar || 'assets/argon-dashboard-master/assets/img/default-user.png'" class="avatar avatar-sm">
                                    <span class="ms-2 text-xs">{{ u.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown me-2" [class.disabled]="!demandTask.user">
                        <button class="btn bg-transparent shadow-none dropdown-toggle pe-0 m-0 fw-normal"
                            [disabled]="!demandTask.user" 
                            type="button" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false">
                            {{ demandTask.task?.name?.length > 20 
                            ? (demandTask.task?.name | slice:0:25) + '...' 
                            : demandTask.task?.name || 'Selecione' }}
                            <!-- {{demandTask.task?.name || 'Selecione'}} -->
                        </button>

                        <ul class="dropdown-menu">
                            <li>
                                <ng-container *ngFor="let t of tasks">
                                    <a class="dropdown-item pointer" 
                                    [class.bg-secondary]="demandTask.task.id == t.id" 
                                    *ngIf="demandTask.user?.area_id == t.area_id"
                                    (click)="demandTask.task = t; !demandTask.new ? save(demandTask) : ''">
                                    {{ t.name }}</a>
                                </ng-container>
                            </li>
                        </ul>
                    </div>
                    <div class="d-flex align-items-center ms-auto">
                        <button (click)="startTimesheet(demandTask.id)" class="btn-timesheet border-0 bg-transparent w-auto p-0" *ngIf="!demandTask.new && demandTask?.user && demandTask.user.id == currentUser.id && ['analyst'].includes(currentUser.group.name)">
                            <img src="../../../../assets/argon-dashboard-master/assets/img/claro/task_timesheet.svg" [class.btn-blink]="demandTask?.timesheet_active" width="25" height="25" alt="">
                            <span class="ms-2 me-4">{{ demandTask?.formatted_timesheet_sum }}</span>
                        </button>
                        <input 
                        type="date" 
                        class="form-control date bg-transparent" 
                        style="width: 125px" 
                        #date [value]="demandTask.due_date" 
                        [(ngModel)]="demandTask.due_date" 
                        (change)="teste(demandTask.due_date)"
                        [max]="max"
                        >
                        <i class="fa-regular fa-circle-xmark ms-3 me-2 delete-task-btn" 
                        *ngIf="!demandTask?.new" (click)="demandTask?.id ? deleteTask(demandTask?.id) : deleteFromArray(i)">
                        </i>
                        <ng-container *ngIf="demandTask?.new">
                            <button class="btn mb-0 save-task pointer ms-auto me-2" 
                            [disabled]="demandTask.done" 
                            (click)="save(demandTask)"
                            ><i class="fa-solid fa-check text-white"></i>
                            </button>
                            <button class="btn mb-0 remove-task pointer" 
                            [disabled]="demandTask.done" 
                            (click)="demandTask?.id ? deleteTask(demandTask?.id) : deleteFromArray(i)"
                            ><i class="fa-solid fa-x text-white"></i>
                        </button>
                        </ng-container>
                    </div>
                </div>
            </div>   
        </div>
        <button type="button" role="button" class="pencil-btn" *ngIf="!demandTask?.new && ['analyst'].includes(currentUser.group.name)"><i (click)="demandTask.edit = !demandTask.edit" class="fa fa-pencil"></i></button>  
        <div class="select-status-box" *ngIf="demandTask.edit">
            <ul class="list-unstyled m-0">
                <li (click)="updateStatus(demandTask, s)" *ngFor="let s of status" class="text-uppercase" style="font-weight: 500" [style.color]="s.color" [style.borderColor]="s.color">{{s.name}}</li>
            </ul>
        </div>
    </div>
    <button class="btn-add" (click)="addTask()" *ngIf="canAdd">+ Adicionar tarefa</button>
</div>