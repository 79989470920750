import { AfterViewInit, AfterContentChecked, Component, ElementRef, Input, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { ChatService } from '../../services/demands/chat.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user-management/user.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewChecked {

  @ViewChild('chatList') private chatList!: ElementRef;
  
  @Input() demandId!: number;
  @Input() list = true;
  comments:any = [];
  chatForm!: FormGroup;
  loading = false;
  currentUser:any;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private chatService: ChatService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();

    this.loadComments();

    this.chatForm = this.formBuilder.group({
      'comment': ['', [Validators.required, Validators.minLength(1)]],
      'user_id': this.currentUser.id,
      'demand_id': this.demandId
    });
  }

  ngAfterViewChecked(): void {
      this.scrollToBottom();
    }

  loadComments(): void {
    console.log(this.demandId);
    console.log(typeof this.demandId);
    this.chatService.getChatsByDemandID(this.demandId).subscribe({
      next: (result) => { 
        console.log(result);
        this.comments = result;
        setTimeout(() => {
          this.scrollToBottom();
        }, 3000);
      },
      error: (err) => console.log(err)
    });
  }

  sendComment(data:any): void {
    this.loading = true;
    if(this.chatForm.valid && !this.submitted){
      this.submitted = true;
      this.chatService.save(data.value).subscribe({
        next: (result) => {
          console.log(result);
          this.chatForm.patchValue({ comment: '' })
          this.comments.push(result);
          this.loading = false;
          this.submitted = false;
        },
        error: () => { }
      });
    }
  }

  scrollToBottom() {
    if (this.chatList) {
      this.chatList.nativeElement.scrollTo({
        top: this.chatList.nativeElement.scrollHeight,
        behavior: 'smooth' 
      });
    }
  }
}
