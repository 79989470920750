
<div class="modal-demand-form" #demandModal>
    <div class="container-fluid" *ngIf="skeleton">
        <div class="row mt-3">
            <div class="col-12 d-flex align-items-center">
                <ngx-skeleton-loader count="1" appearance="circle"
                [theme]="{height: '50px', width: '50px', marginBottom: '5px'}">
                </ngx-skeleton-loader>
                <div class="d-flex flex-column ms-3">
                    <ngx-skeleton-loader count="1" appearance="line"
                    [theme]="{height: '22px', width: '220px', marginBottom: '5px'}">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" appearance="line"
                    [theme]="{height: '15px', width: '160px'}">
                    </ngx-skeleton-loader>
                </div>
            </div>
            <div class="col-12 mt-4 mb-4">
                <div class="d-flex flex-column ms-3">
                    <ngx-skeleton-loader count="1" appearance="line"
                    [theme]="{height: '24px', width: '220px', marginBottom: '5px'}">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" appearance="line"
                    [theme]="{height: '17px', width: '160px'}">
                    </ngx-skeleton-loader>
                </div>
            </div>
            <div class="col-4" *ngFor="let s of [1,2,3,4,5,6,7,8,9]">
                <ngx-skeleton-loader count="1" appearance="line"
                [theme]="{height: '44px', marginBottom: '10px'}">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div class="modal-header py-0 px-4" *ngIf="!skeleton">
        <app-emoji-selector 
        (emojiSelected)="addEmoji($event)"
        [emoji]="demand?.emoji"
        positionList="bottom"></app-emoji-selector>
        <h6 class="modal-title text-white mb-0 ms-3 me-auto demand-name">
            <ng-container *ngIf="demand.project?.code && demand?.item_id">
                {{demand.project?.code}}.{{demand?.item_id}}
            </ng-container> 
            {{ demand?.name }}
        </h6>
        <div class="view">
            <div class="row">
                <div class="col-9 d-flex align-items-center">
                    <span class="text-white ms-auto text-sm d-flex align-items-center me-3" id="demand-deadline">
                        <img src="assets/argon-dashboard-master/assets/img/claro/icons/calendar.svg" class="me-2" alt="" width="20" style="filter: contrast(10) brightness(10);">
                        {{ demand?.deadline | date: 'dd/MM/y' }}
                    </span>
                    <div class="badge p-0 bg-white">
                        <span class="badge-status" 
                        [ngStyle]="{
                        'background-color': addOpacity(demand.status.color, 0.1),
                        'color': demand.status.color
                        }">
                        {{ demand.status.name }}
                         </span>
                    </div>
                </div>
                <!-- <div class="col-3">
                    <button type="button" *ngIf="showCloseButton" class="btn btn-sm btn-primary mb-0" (click)="cancel()" aria-label="Close">x</button>
                </div> -->
            </div>
        </div>
    </div>

    <div class="modal-body p-3 demand-content" *ngIf="!skeleton">
        <div class="container-fluid">
            <div class="row" *ngIf="showRequester">
                <div class="col requester ps-0">
                    <img class="me-3" [src]="demand.user?.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'" alt="" [ngbTooltip]="demand?.user.name">
                    <div>
                        <p class="mb-0">{{ demand.user?.name }}</p>
                        <p class="mb-0 text-xs">{{ demand.user?.email }} | {{ demand.user?.phone | mask: '(00) 00000-0000' }}</p>
                    </div>
                </div>
                <div class="col-auto text-end border-start d-flex align-items-center justify-content-center text-uppercase ps-4 project-link" *ngIf="demand?.project">
                     <a (click)="cancelModal.emit(true)" [routerLink]="'/' + currentUser.group.name + '/projects/project/' + demand.project.id">{{demand.project.name}} <i class="fa-solid fa-arrow-up-right-from-square ms-2"></i></a>
                </div>
            </div>
            <div class="row p-3 mt-3" id="demand-area" style="background-color: #FAF4F4">
                <div class="col-12">
                    <p class="m-0">Tipo da demanda</p>
                    <p class="fw-bold text-uppercase m-0">{{demand.area.name}}</p>
                </div>
                <div class="col-12 mt-1">
                    <app-fields 
                    *ngIf="fields"
                    [fields]="fields"
                    [area_id]="demand.area_id"
                    [showAreaSelector]="showAreaSelector"
                    type="demand"
                    [context]="context"
                    (changeArea)="setArea($event)"
                    (allFieldsFilled)="allFilled = $event"
                    [clickSubmit]="clickSubmit"
                    columnsPerLine="2"
                    [model_id]="demand.id"
                    [saveFieldsOnSelect]="true"
                    ></app-fields>
                </div>
                <div class="col-12" *ngIf="['requester', 'screening'].includes(currentUser.group.name)">
                    <label class="form-label mb-1 mt-4" style="font-weight: 700; margin-bottom: 0.5rem">Descrição da demanda</label>
                    <textarea name="" disabled id="requester-description" class="form-control border-0 bg-white" [value]="demand.requester_description" rows="5"></textarea>
                </div>
            </div>
                
            <div class="row mt-3" *ngIf="['screening', 'leadership', 'analyst'].includes(currentUser.group.name)">
                <div class="tasks col-6">
                    <app-tasks 
                    [demand_id]="demand_id" 
                    [canAdd]="
                    ['triagem'].includes(demand.status.identifier) && ['screening'].includes(currentUser.group.name) 
                    || 
                    ['em_andamento'].includes(demand.status.identifier)" 
                    [max]="demand.deadline" 
                    (hasOpenTasks)="hasOpenTasks = $event"
                    ></app-tasks>
                </div>
                <div class="pill-menu col-6">
                    <ul class="nav nav-pills mb-3 bg-white" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link fw-bold text-uppercase active" id="pills-chat-tab"
                                data-bs-toggle="pill" data-bs-target="#pills-chat" type="button" role="tab"
                                aria-controls="pills-chat" aria-selected="true">Comentários</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button *ngIf="['analyst'].includes(currentUser.group.name)" class="nav-link fw-bold text-uppercase" id="pills-activities-tab"
                                data-bs-toggle="pill" data-bs-target="#pills-activities" type="button"
                                role="tab" aria-controls="pills-activities"
                                aria-selected="false">Atividades</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link fw-bold text-uppercase" id="pills-history-tab"
                                data-bs-toggle="pill" data-bs-target="#pills-history" type="button" role="tab"
                                aria-controls="pills-history" aria-selected="false">Histórico</button>
                        </li>
                    </ul>
                    <div class="tab-content px-3" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-chat" role="tabpanel" aria-labelledby="pills-chat-tab" tabindex="0">
                            <app-chat [demandId]="demand_id"></app-chat>
                        </div>
                        <div class="tab-pane fade" id="pills-activities" role="tabpanel" aria-labelledby="pills-activities-tab" tabindex="0">
                            <app-events [demand_id]="demand_id"></app-events>
                        </div>                                    
                        <!-- <div class="tab-pane fade" id="pills-recurring" role="tabpanel" aria-labelledby="pills-recurring-tab" tabindex="0">
                            ...
                        </div> -->
                        <div class="tab-pane fade" id="pills-history" role="tabpanel" aria-labelledby="pills-history-tab" tabindex="0">
                            <app-history [demandId]="demand_id" #history></app-history>
                        </div>
                        <!-- <div class="tab-pane fade" id="pills-timesheet" role="tabpanel" aria-labelledby="pills-timesheet-tab" tabindex="0" *ngIf="showTimesheet">
                            ...
                        </div> -->
                    </div>
                </div>
                <hr class="horizontal dark my-5">
            </div>

            <div class="row" *ngIf="currentUser.group.name !== 'requester'">
                <div class="pill-menu">
                    <ul class="nav nav-pills mb-3 bg-white" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link fw-bold text-uppercase active" id="pills-briefing-tab"
                                data-bs-toggle="pill" data-bs-target="#pills-briefing" type="button" role="tab"
                                aria-controls="pills-briefing" aria-selected="true">Briefing</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link fw-bold text-uppercase attachment-title" id="pills-attachment-list-tab"
                                data-bs-toggle="pill" data-bs-target="#pills-attachment-list" type="button"
                                role="tab" aria-controls="pills-attachment-list"
                                aria-selected="false">Anexos <span class="badge bg-danger">{{demand_files?.length}}</span></button>
                        </li>
                    </ul>
                    <div class="tab-content px-3" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-briefing" role="tabpanel" aria-labelledby="pills-briefing-tab" tabindex="0">
                            <ckeditor [editor]="Editor" [(ngModel)]="briefing" (blur)="updateBriefing()" (focus)="showMessageSaveBriefing = false"></ckeditor>
                            <p *ngIf="showMessageSaveBriefing" class="alert alert-warning text-light font-weight-bold text-xs mt-1 py-1">Salvando conteúdo ...</p>
                        </div>
                        <div class="tab-pane fade" id="pills-attachment-list" role="tabpanel" aria-labelledby="pills-attachment-list-tab" tabindex="0">
                            <app-file-upload [files]="demand_files" [context]="{context: 'demands', id: demand_id}" [style]="2"></app-file-upload>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer" *ngIf="!skeleton && currentUser.group.name !== 'requester'">
        <div class="row" *ngIf="showActionsButtons">
            <div class="col-12 d-flex justify-content-between">
                <!-- <button class="btn-action btn-rating me-4" *ngIf="demand.status.identifier !== 'em_aprovacao'" (click)="evaluation()">Enviar avaliação</button> -->
                <button 
                id="btn-action-area"
                class="btn-action btn-add-demand me-auto"
                (click)="duplicateDemand()"
                *ngIf="!['requester'].includes(currentUser.group.name)"
                >Acionar área</button>

                <button
                class="btn-action btn-finish"
                *ngIf="!hasOpenTasks && demand.status.identifier == 'em_aprovacao' && currentUser.group.name == 'screening'"
                 (click)="finish()"
                 >Concluir demanda</button>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-6">
                <button type="button" name="button" *ngIf="!modal" [disabled]="loading" routerLink="/demands"
                    class="btn btn-light m-0">
                    Voltar
                </button>
            </div>
            <div class="col-lg-6 col-12 mx-auto d-none">

                <div class="d-flex justify-content-end">
                    <button type="button" name="button" [disabled]="loading" (click)="cancel()" class="btn btn-light m-0">
                        {{ modal ? 'Fechar' : 'Cancelar' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #content let-modal>
    <app-requester-demands-form [demand_id]="demand_id" (close)="modal.dismiss('Cross click')"></app-requester-demands-form>
</ng-template>