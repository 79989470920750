import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService extends BaseService {

  // demandTaskIdActive:any = null;
  active:any = null;

  private startTime = new BehaviorSubject<number | null>(null);
  startTime$ = this.startTime.asObservable();

  private endTime = new BehaviorSubject<number | null>(null);
  endTime$ = this.endTime.asObservable();

  setStartTime(demandTaskId:number): void {
      this.startTime.next(demandTaskId);
  }

  setEndTime(demandTaskId:any){ 
    this.endTime.next(demandTaskId);
  }

  saveTitle(data:any) { 
    return this.post(`timesheets`, data);
  }

  start(demandTaskId:number) { 
    return this.post(`timesheets/${demandTaskId}/start`, {})
  }

  stop (data:any) { 
    return this.post(`timesheets/stop`, data)
  }

  // getByDemandId(demandTaskId:number) {
  //  return this.get(`timesheets/${demandTaskId}`);
  // }

  getAll(data:any = {}) { 
    return this.post(`timesheets/filter`, data);
  }

  getUserActiveTimesheet() {
    console.log('VERIFICANDO TIMESHEET');
    console.log('VERIFICANDO TIMESHEET');
    console.log('VERIFICANDO TIMESHEET');
    console.log('VERIFICANDO TIMESHEET');
    console.log('VERIFICANDO TIMESHEET');
   return this.get(`timesheets/active`);
  }

}
