import { Component, OnInit } from '@angular/core';
import { TimesheetService } from '../../services/demands/timesheet.service';
import { TaskService } from '../../services/demands/task.service';
import { ChatService } from '../../services/demands/chat.service';
import { DemandEventsService } from '../../services/demands/demand-events.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user-management/user.service';
import { DemandsService } from '../../services/demands/demands.service';

@Component({
  selector: 'app-dashboard-cards',
  templateUrl: './dashboard-cards.component.html',
  styleUrl: './dashboard-cards.component.scss'
})
export class DashboardCardsComponent implements OnInit {

  data: any 
  skeleton = true
  status:any;
  timesheet:any;
  events: any;
  demand_id:any;

  lastMessage: any;
  currentUser:any;

  constructor(
    private timesheetService: TimesheetService,
    private demandsService: DemandsService,
    private tasksService: TaskService,
    private chatService: ChatService,
    private demandEventsService: DemandEventsService,
    private userService: UserService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();
    if(this.currentUser.group.name === 'analyst') this.getTasksStatusCount();
    else this.getDemandsStatusCount();

    this.timesheetService.endTime$.subscribe((demandTaskId) => {
      this.getTimesheetSum();
    });

    this.getEvents();
  }

  getEvents() { 
    this.demandEventsService.getTodayEvents().subscribe({
      next: (result) => {
        console.log(result)
        this.events = result
      },
      error: (err) => console.log(err)
    })
  }
  
  getTimesheetSum() { 
    let today = new Date().toISOString().split('T')[0]
    this.timesheetService.getAll({
      // user_id: this.currentUser.id,
      end: today
    }).subscribe({
      next: (result:any) => { 
        this.timesheet = result;
        console.log(this.timesheet);
      },
      error: (err) => console.log(err)
    });
  }

  getTasksStatusCount () { 
    this.tasksService.getTasksStatusCount().subscribe({
      next: (result) => this.status = result,
      error: (err) => console.log(err)
    });
  }

  getDemandsStatusCount () { 
    this.demandsService.getDemandsStatusCount().subscribe({
      next: (result) => this.status = result,
      error: (err) => console.log(err)
    });
  }

  getLastMessage() {
    this.chatService.getLastMessage().subscribe({
      next: (result) => {
        this.lastMessage = result
      },
      error: (err) => console.log(err)
    })
  }

  showDemand(demand_id: number, content: any) {
    this.demand_id = demand_id
    this.modalService.open(content, { windowClass: 'custom-modal', size: 'xl' });
  }

}
