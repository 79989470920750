import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DemandsService } from 'src/app/shared/services/demands/demands.service';
import { StatusService } from 'src/app/shared/services/demands/status.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import emojis from '../../../../assets/argon-dashboard-master/assets/img/claro/icons/emojis/emojis.json';
import { UserService } from '../../services/user-management/user.service';
import { AreaService } from '../../services/user-management/area.service';

@Component({
  selector: 'app-box-kanban',
  templateUrl: './box-kanban.component.html',
  styleUrls: ['./box-kanban.component.scss']
})
export class BoxKanbanComponent implements OnInit, AfterViewInit {

  @Input() title = 'Demandas'
  @Input() showFiltersBox = false;
  @Input() addNew = true;
  @Input() context = 'demand'
  @Input() openDemandPopup = true;
  @Output() updateBoxStatus = new EventEmitter()

  users: any;
  currentUser: any = null;

  // Filters
  user_id: any = ''
  deadline: any = ''
  area_id: any = ''
  status_id: any = ''
  query: any = ''
  order: any = '';
  range: any = 'all';
  limit: any = null;

  areas: any = [];
  status: any = []
  skeleton = true

  demand_id: any = 0
  demandsCount = 0;

  header: any;

  constructor(
    private demandService: DemandsService,
    private statusService: StatusService,
    private userService: UserService,
    private areaService: AreaService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();
    this.area_id = this.currentUser.area_id;

    this.getUsers();
    this.getAreas();
    this.getKanban();
  }

  @ViewChild('kanbanContent', { static: false }) kanbanContent!: ElementRef;

  private isDown = false;
  private startX = 0;
  private scrollLeft = 0;

  ngAfterViewInit(): void {
    if (!this.kanbanContent) {
      console.error('Erro: kanbanContent não foi inicializado corretamente.');
    }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (!this.kanbanContent?.nativeElement) return;

    this.isDown = true;
    this.startX = event.pageX - this.kanbanContent.nativeElement.offsetLeft;
    this.scrollLeft = this.kanbanContent.nativeElement.scrollLeft;
  }

  @HostListener('mouseleave')
  @HostListener('mouseup')
  onMouseUp() {
    this.isDown = false;
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isDown || !this.kanbanContent?.nativeElement) return;
    event.preventDefault();

    const x = event.pageX - this.kanbanContent.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 2; // Ajuste de velocidade
    this.kanbanContent.nativeElement.scrollLeft = this.scrollLeft - walk;
  }

  getAreas() {
    this.areaService.getAll().subscribe({
      next: (result) => this.areas = result,
      error: (err) => console.log(err)
    });
  }

  getUsers() {
    this.userService.getByGroup('analyst').subscribe({
      next: (result) => this.users = result,
      error: (err) => console.log(err)
    });
  }

  getKanban() {
    let filter = {
      deadline: this.deadline,
      area_id: this.area_id,
      user_id: this.user_id,
      status_id: this.status_id,
      query: this.query,
      range: this.range
    };

    this.statusService.getKanban(this.context, filter).subscribe({
      next: (result) => {
        this.status = result;
        this.demandsCount = this.status.reduce((total, s) => total + s.demands.length, 0);
        this.skeleton = false;
      },
      error: (err) => console.log(err)
    });
  }

  resetFilters() {
    this.limit = '';
    this.query = '';
    this.order = '';
    this.range = 'all';
    this.context = 'demand';
    this.deadline = '';
    this.area_id = '';
  }

  getEmoji(emoji_id: number) {
    if (!emoji_id) return null;
    let emoji = emojis.find((e: any) => e.id == emoji_id);
    return emoji ? emoji.emoji : null;
  }

  blockColumnTransfer(drag: CdkDrag<any>, drop: CdkDropList<any>): boolean {
    return false;
  }

  drop(event: CdkDragDrop<string[]>, status: any) {
    return;

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      this.demandService.updateStatus({ id: event.item.data.id, status_id: status.id, context: status.context }).subscribe({
        next: () => this.updateBoxStatus.emit(true),
        error: (err) => console.log(err)
      });
    }
  }

  newDemand(content: any) {
    this.modalService.open(content, { size: 'lg' });
  }

  onColumnDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.status, event.previousIndex, event.currentIndex);
    const ids = this.status.map((item: any) => item.id);
    this.statusService.setNewOrder({ array_ids: ids }).subscribe({
      next: () => { },
      error: (err) => console.log(err)
    });
  }

  showDemand(demand_id: number, content: any) {
    this.demand_id = demand_id;
    this.modalService.open(content, { windowClass: 'custom-modal', size: 'xl' });
  }

  demandHeader(data: any) {
    this.header = data;
  }
}