<div class="card">
    <div class="card-body">
        <div class="box-userbox-users">
            <form [formGroup]="userForm" (ngSubmit)="onSubmit()">

                <h4>EDITAR PERFIL</h4>
                <div class="item d-flex mb-4" *ngIf="!skeleton">
                    <div *ngIf="user">
                        <app-file-upload 
                        [context]="{context: 'users', id: user?.id}"
                        [files]="[user.avatar]"
                        [style]="3"
                        [allowedExtensions]="['jpg','jpeg','png','gif','bmp']"
                        (saved)="getUser()"
                        ></app-file-upload>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-1 user-name">{{ user.name }}</h6>
                        <p class="mb-0">{{user?.group.name}} - {{ user.area.name }}</p>
                        <p class="mb-0">{{user.email}}</p>
                        <!--<div class="d-flex text-sm mt-1">
                            <a href="#" class="me-1">Alterar Senha</a> | <a href="#" class="ms-1">Redefinir por email</a>
                        </div>-->
                    </div>
                    <!-- <div>
                        <select [ngModel]="user.group_id" #group (change)="updateUserGroup(group.value)" class="form-select mt-3 ms-4 px-5">
                            <option value="0">Selecione o tipo</option>
                            <option [value]="g.id" *ngFor="let g of groups">{{ g.label }}</option>
                        </select>
                    </div> -->
                </div>
                <h4 class="mt-4">DADOS PESSOAIS</h4> 
                <div class="box-form">
                    <div class="row">
                        <div class="col-lg-12">
                            <label class="form-label">Nome</label>
                            <input [ngClass]="{'is-invalid': userForm.get('name')?.invalid && userForm.get('name')?.touched}" type="text" class="form-control" formControlName="name">
                            <div *ngIf="userForm.get('name')?.invalid && userForm.get('name')?.touched" class="text-danger">
                                <small *ngIf="userForm.get('name')?.errors?.['required']">Nome é obrigatório.</small>
                                <small *ngIf="userForm.get('name')?.errors?.['name']">Nome inválido.</small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <label class="form-label">E-mail</label>
                            <input [ngClass]="{'is-invalid': userForm.get('email')?.invalid && userForm.get('email')?.touched}" type="email" class="form-control" formControlName="email">
                            <div *ngIf="userForm.get('email')?.invalid && userForm.get('email')?.touched" class="text-danger">
                                <small *ngIf="userForm.get('email')?.errors?.['required']">E-mail é obrigatório.</small>
                                <small *ngIf="userForm.get('email')?.errors?.['email']">E-mail inválido.</small>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <label class="form-label">Telefone</label>
                            <input [ngClass]="{'is-invalid': userForm.get('phone')?.invalid && userForm.get('phone')?.touched}" type="phone" mask="(00) 00000-0000" class="form-control" formControlName="phone">
                            <div *ngIf="userForm.get('phone')?.invalid && userForm.get('phone')?.touched" class="text-danger">
                                <small *ngIf="userForm.get('phone')?.errors?.['required']">Telefone é obrigatório.</small>
                            </div>
                        </div>
                    </div>
                    <app-fields 
                    *ngIf="fields"
                     [fields]="fields"
                     [model_id]="user.id"
                     context="user"
                     [showAreaSelector]="false"
                     style="background: #fff"
                     [styles]="{labelColor: '#757575'}"></app-fields>
                   
                </div>


                <h4 class="mt-4">ALTERAR SENHA</h4> 
                <div class="box-form">
                    <div class="row">
                        <div class="col-lg-6">
                            <label class="form-label">Nova senha</label>
                            <input placeholder="Digite aqui" [ngClass]="{'is-invalid': userForm.get('password')?.invalid && userForm.get('password')?.touched}" type="password" class="form-control" formControlName="password">
                            <div *ngIf="userForm.get('password')?.invalid && userForm.get('password')?.touched" class="text-danger">
                                <small *ngIf="userForm.get('password')?.errors?.['minlength']">Senha deve ter pelo menos 6 caracteres.</small>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label class="form-label">Confirmar senha</label>
                            <input placeholder="Digite aqui" [ngClass]="{'is-invalid': userForm.get('confirmPassword')?.invalid && userForm.get('confirmPassword')?.touched || (userForm.hasError('passwordMismatch') && userForm.get('confirmPassword')?.touched)}" type="password" class="form-control" formControlName="confirmPassword">
                            <div *ngIf="userForm.hasError('passwordMismatch') && userForm.get('confirmPassword')?.touched" class="text-danger">
                                <small>As senhas não coincidem.</small>
                            </div>
                        </div>
                    </div>
                </div>

                <h4 class="mt-4">PERMISSÕES</h4> 
                <div class="box-form text-center">
                    <p class="mb-0">Nenhuma permissão no momento</p>
                </div>

                <div class="text-end mt-4">
                    <button type="submit" [disabled]="!userForm.dirty || userForm.invalid" class="btn btn-primary mb-0 btn-save">Salvar</button>
                </div>

            </form>

        </div>
    </div>
</div>